<template>
    <div class="freight-detail">
        <div class="top">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="btn-back">
                <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="freight-title">
            <span>{{freight.title}}</span>
        </div>
        <el-table :data="freight.freightList" style="width: 100%; flex: 1" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                  :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column label="运送方式" prop="delivery_mode"></el-table-column>
            <el-table-column label="运送到（国内）" align="center" width="320">
                <template slot-scope="scope">
                    <span>{{scope.row.city?scope.row.city:'为划分的区域将归于该模板下的默认运费'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="首件（个）" align="center">
                <template slot-scope="scope">
                    <span>
                          {{scope.row.city?scope.row.first_thing:freight.defaultNum}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="首费（元）" align="center">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.first_cost:freight.defaultPrice}}
                    </span>
                </template>
            </el-table-column>
             <el-table-column label="续件（个）" align="center">
               <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.add_thing:freight.addNum}}
                    </span>
                </template>
            </el-table-column>
           <el-table-column label="续费（元）" align="center">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.add_cost:freight.addPrice}}
                    </span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {stuOpTrainData} from '@/utils/apis'
    export default {
        name: "FreightDetail",
        data(){
            return{
                train_id:Number(this.$route.query.train_id) || null,
                // 运费单模板
                freight: {
                    title: '',
                    defaultPrice: 0,
                    defaultNum: 0,
                    addNum: 0,
                    addPrice: 0,
                    exemptPostage: 0,
                    freightList: [],
                },
                freightTemplateTitle:'',//运送模板
            }
        },
        mounted() {
            this.getStuOpTrainDataDetail()
        },
        methods:{
            //获取网店详情
            getStuOpTrainDataDetail(){
                let params = {
                    train_id:this.train_id,
                }
                stuOpTrainData(params).then((res) => {
                    if (res.data.freight_info.stu_answer){
                        this.freight.title = res.data.freight_info.stu_answer.freight_template_title;
                        this.freight.defaultPrice = res.data.freight_info.stu_answer.default_price;
                        this.freight.defaultNum = res.data.freight_info.stu_answer.default_num;
                        this.freight.addPrice = res.data.freight_info.stu_answer.add_price;
                        this.freight.addNum = res.data.freight_info.stu_answer.add_num;
                        this.freight.freightList = res.data.freight_info.stu_answer.freight_setting;
                        this.freight.exemptPostage = res.data.freight_info.stu_answer.is_exempt_postage
                    }
                    if (res.data.freight_info.stu_answer.is_exempt_postage === 1) {
                        //商家包邮
                        this.freight.freightList = [{
                            add_cost: 0,
                            add_thing: 1,
                            city: "全国",
                            delivery_mode: "快递",
                            first_cost: 0,
                            first_thing: 1,
                        }]
                    } else {
                        if (res.data.freight_info.stu_answer.freight_setting.length === 0) {
                            this.freight.freightList = [{
                                add_cost: res.data.freight_info.stu_answer.default_price,
                                add_thing: res.data.freight_info.stu_answer.default_num,
                                city: "为划分的区域将归于该模板下的默认运费",
                                delivery_mode: "快递",
                                first_cost: res.data.freight_info.stu_answer.default_price,
                                first_thing: res.data.freight_info.stu_answer.default_num,
                            }]
                        }
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    }
</script>

<style scoped lang="scss">
.freight-detail{
    padding: 0 20px;
    .freight-detail-title{
        padding: 10px 0;
    }
    .top{
        position: relative;
        .btn-back{
            position: absolute;
            top: -12px;
            right: 20px;
        }
    }
    .freight-title {
        font-size: 20px;
        padding-left: 15px;
        line-height: 1;
        margin: 20px 0 30px;
        position: relative;
        display: flex;
        align-items: center;
        /*justify-content: space-between;*/
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            transform: translateY(-50%);
        }
    }
}
</style>